export enum CustomActionsEnum {
  RemoveCartId = 'removeCartId',
  MutateCartDetails = 'mutateCartDetails',
  UpdateUserAddresses = 'updateUserAddresses',
  RedirectToOrderFast = 'redirectToOrderFast',
  UpdatePaymentMethods = 'updatePaymentMethods',
  RefetchStoreSettingsAndCartItems = 'refetchStoreSettingsAndCartItems',
  RefetchBranchDetails = 'refetchBranchDetails',
  UpdateSelectedBranchDetails = 'updateSelectedBranchDetails',
  RefetchModeDetails = 'refetchModeDetails',
  RemoveVoucher = 'removeVoucher',
  Recalculate = 'reCalculateSlots',
  RedirectToEditAddress = 'redirectToEditAddress',
  RemoveCartItemsOutOfSync = 'removeCartItemsOutOfSync',
  RemoveUnavailableOptions = 'removeUnavailableOptions',
}

export enum CustomVariableEnum {
  OrderMode = 'orderMode',
}

export enum FONT_ENUM {
  MODERN = 'typography_cabin_robotoCondensed',
  CLEAN = 'typography_openSans_Raleway',
  FANCY = 'typography_sourceSansPro_playfairDisplay',
  BOLD = 'typography_poppins_abril',
  LIGHT = 'typography_serif_oxygen',
  MONO = 'typography_crimson_unica',
}

export enum OS {
  ANDROID = 'Android',
  IOS = 'iOS',
  WINDOWS = 'Windows',
  MAC = 'Mac OS',
  LINUX = 'Linux',
  UNKNOWN = 'Unknown OS',
}

export enum USER_AGENT_TYPES {
  SAFARI = 'Safari',
  INSTAGRAM = 'Instagram',
}

export enum VENDOR_TYPES {
  APPLE = 'Apple Computer, Inc.',
}

export enum NAVIGATOR_PERMISSION_STATES {
  GRANTED = 'granted',
  PROMPT = 'prompt',
  DENIED = 'denied',
}

export enum NAVIGATOR_PERMISSION_QUERY_NAMES {
  GEO_LOCATION = 'geolocation',
}

export enum NAVIGATOR_PERMISSION_ACTIONS {
  CHANGE = 'change',
}
