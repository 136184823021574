import * as Sentry from '@sentry/nextjs';

const externalLogger = {
  async log({ error, extraData, tags }) {
    const { requestSchema, cookies, ...rest } = extraData || {};
    Sentry.withScope(scope => {
      if (tags) {
        Object.entries(tags).forEach(([key, value]) => scope.setTag(key, String(value)));
      }
      if (cookies) {
        scope.setExtra('cookies', cookies);
      }
      if (rest) {
        scope.setExtras(rest);
      }
      Sentry.captureException(error);
    });
    await Sentry.flush(2000);
  },
};

const LoggerLevels = {
  error({ error, extraData, tags }: { error: any; extraData: any; tags?: Record<string, string> }) {
    externalLogger.log({ error, extraData, tags });
  },
};

const logger = {
  ...LoggerLevels,
};

export default logger;
